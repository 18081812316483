<template>
  <div class="testSearch-wrap">
    <headNav active="4"></headNav>
    <div class="testSearch-content">
      <div class="testSearch-main">
        <div class="left"></div>
        <div class="right">
          <div class="title">
            <div>{{ titleObj.title1 }}</div>
            <div>{{ titleObj.title2 }}</div>
          </div>
          <div class="form-wrap">
            <div>
              <div class="label">姓名</div>
              <div class="input-wrap">
                <input v-model="form.name" placeholder="请输入您的姓名" />
              </div>
            </div>
            <div style="margin-top: 55px">
              <div class="label">证件号（身份证、台胞证、护照及其他）</div>
              <div class="input-wrap">
                <input
                  v-model="form.idNumber"
                  @keyup.enter="search"
                  placeholder="请输入您的证件号"
                />
              </div>
            </div>
            <div class="btn" @click="search">登录</div>
          </div>
        </div>
      </div>
    </div>
    <footNav />
  </div>
</template>
<script>
import headNav from "../components/header2.vue";
import footNav from "../components/footer2.vue";
export default {
  components: { headNav, footNav },
  data() {
    return {
      titleObj: {
        title1: "",
        title2: "",
      },
      form: {
        name: "",
        idNumber: "",
        type: "",
      },
    };
  },
  mounted() {
    this.form.type = this.$route.query.type;
    if (this.form.type == "test") {
      this.titleObj = {
        title1: "2025年苏州民族管弦乐团",
        title2: "社会艺术水平考级查询下载",
      };
    } else if (this.form.type == "teenager") {
      this.titleObj = {
        title1: "第四届苏州市青少年民族器乐展演",
        title2: "查询下载",
      };
    } else if (this.form.type == "teenagerScoreInquiry") {
      this.titleObj = {
        title1: "第四届苏州市青少年民族器乐展演",
        title2: "成绩查询",
      };
    } else if (this.form.type == "testScoreInquiry") {
      this.titleObj = {
        title1: "2025年苏州民族管弦乐团",
        title2: "社会艺术水平考级成绩查询",
      };
    }
  },
  methods: {
    search() {
      if (this.form.name && this.form.idNumber) {
        if (this.form.type == "teenagerScoreInquiry" || this.form.type == "testScoreInquiry") {
          // 成绩查询
          this.scoreInquirySearch();
        } else {
          // 准考证查询
          this.admissionTicketSearch();
        }
      } else {
        this.$message.error("请正确输入");
      }
    },
    // 准考证查询
    admissionTicketSearch() {
      let ajaxUrl = "";
      if (this.form.type == "test") {
        ajaxUrl = "examRegistration/registration/info";
      } else {
        ajaxUrl = "instrument/competition/info";
      }
      this.$ajax.get(this.$ajax.localOrOnline() + ajaxUrl, this.form).then((res) => {
        if (res.code == 200) {
          if (res.data) {
            this.$router.push({ name: "testUserInfo", query: this.form });
          } else {
            this.$alert("没有获取到您的报名信息", {
              confirmButtonText: "确定",
            });
          }
        } else {
          this.$message(res.message);
        }
      });
    },
    // 成绩查询
    scoreInquirySearch() {
      let ajaxUrl = "";
      if (this.form.type == "testScoreInquiry") {
        ajaxUrl = "examRegistration/getScore";
      } else {
        ajaxUrl = "instrument/competition/getInstrumentScore";
      }
      this.$ajax
        .get(this.$ajax.localOrOnline() + ajaxUrl, this.form)
        .then((res) => {
          if (res.code == 200) {
            if (res.data && res.data.length) {
              this.$router.push({ name: "scoreInquiry", query: this.form });
            } else {
              this.$alert("没有获取到您的考试信息", {
                confirmButtonText: "确定",
              });
            }
          } else {
            this.$message(res.message);
          }
        });
    },
  },
};
</script>
<style scoped lang="scss">
.testSearch-wrap {
  .testSearch-content {
    height: 900px;
    background-image: url("../assets/testSearch-bg-1.png");
    background-size: 100% 100%;
    margin: 0 auto;
    padding: 123px 0;
    .testSearch-main {
      width: 1100px;
      height: 650px;
      margin: 0 auto;
      display: flex;
      .left {
        width: 500px;
        height: 100%;
        background-image: url("../assets/testSearch-bg-2.png");
        background-size: 100% 100%;
      }
      .right {
        width: 600px;
        background: #fff;
        padding-top: 74px;
        .title {
          font-size: 34px;
          font-family: STZhongsong, STZhongsong-Regular;
          text-align: CENTER;
          color: #845c46;
        }
        .form-wrap {
          width: 500px;
          margin: 40px auto 0;
          text-align: left;
          .label {
            font-size: 18px;
            color: #333333;
            font-weight: 400;
          }
          .input-wrap {
            border-bottom: 1px solid #999999;
            padding: 10px 0;
            input {
              width: 100%;
              border: none;
              padding-left: 2px;
              outline: none;
            }
          }
          input::-webkit-input-placeholder {
            font-size: 14px;
            color: #999999;
          }
          .btn {
            width: 100%;
            line-height: 57px;
            background: #bf783d;
            border-radius: 4px;
            text-align: center;
            color: #fff;
            margin: 80px auto 0;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>