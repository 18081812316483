<template>
    <div class="common-wrap">
      <headNav active="7"></headNav>
      <div class="common-contents">
        <div class="banner">
          <img
            src="@/assets/version2/artTeamUnion-1.png"
            alt=""
            srcset=""
            class="img"
            v-if="$i18n.locale == 'zh'"
          />
          <img src="" alt="" srcset="" class="img" v-else />
        </div>
        <div style="background: #f2f6f7">
          <div class="contents">
            <div class="pt-106 flex between">
              <div class="f-36 f-bold t-l" style="color: #444444; width: 431px">
                <div>苏州市民族管弦乐</div>
                <div>艺术团队联合会</div>
              </div>
              <div style="color: #5b5b5b; width: 771px" class="f-16 t-l">
                <p>
                  苏州市民族管弦乐艺术团队联合会是由苏州民族管弦乐团等单位发起、苏州市文化和民政部门批准，为苏州民族器乐艺术作曲、理论、指挥、教育、演奏等人才的培养平台和非职业民族器乐爱好者及民乐社团的传播交流平台，由中国广播民族乐团艺术总监兼首席指挥、苏州民族管弦乐团艺术总监兼首席指挥彭家鹏担任会长。
                </p>
                <p class="mt-30">
                  联合会的成立，对于整合江南丝竹在新时代的品牌效应，进一步构建完善我市公共文化服务体系，满足人民群众在新时代的精神文化需求，具有重要的意义。
                </p>
                <p class="mt-30">
                  会长彭家鹏表示，联合会的成立体现了苏州对新时代民族音乐文化事业再出发的殷切期望。自己将与理事会班子成员一起，用创新机制、用满腔热情、用不懈奋斗，共同把联合会建设好、发展好，打造新时代中国苏州民族音乐文化品牌。
                </p>
              </div>
            </div>
            <div class="mt-19 t-r">
              <img
                src="@/assets/version2/artTeamUnion-2.png"
                alt=""
                srcset=""
                style="width: 1000px; height: 480px"
              />
            </div>
            <div class="mt-106 flex between f-26">
              <div
                @click="change(1)"
                class="t-c content-item cur"
                :class="active == 1 ? 'content-item-acitve' : ''"
              >
                苏州民族音乐展演
              </div>
              <div
                @click="change(2)"
                class="t-c content-item cur"
                :class="active == 2 ? 'content-item-acitve' : ''"
              >
                大运河文化传承展示周
              </div>
              <div
                @click="change(3)"
                class="t-c content-item cur"
                :class="active == 3 ? 'content-item-acitve' : ''"
              >
                苏州市新年音乐会
              </div>
            </div>
          </div>
          <div class="pt-81" style="background: #f3f3f3" v-if="active == 1">
            <div class="contents">             
             <img src="@/assets/version2024/performance-1.png" alt="" srcset="" class="wd">
             <img src="@/assets/version2024/performance-2.png" alt="" srcset="" class="wd mt-58">
             <img src="@/assets/version2024/performance-3.png" alt="" srcset="" class="wd mt-49">
             <img src="@/assets/version2024/performance-4.png" alt="" srcset="" class="wd mt-58">
             <img src="@/assets/version2024/performance-5.png" alt="" srcset="" class="wd mt-49">
             <img src="@/assets/version2024/performance-6.png" alt="" srcset="" class="wd mt-58">
             <img src="@/assets/version2024/performance-21.png" alt="" srcset="" class="wd mt-49">
             <img src="@/assets/version2024/performance-22.png" alt="" srcset="" class="wd mt-58">
           </div>
          </div>
          <!-- 大运河 -->
          <div class="pt-81" style="background: #f3f3f3" v-if="active == 2">
            <div class="contents">     
            <img src="@/assets/version2024/performance-7.png" alt="" srcset="" class="wd">
             <img src="@/assets/version2024/performance-8.png" alt="" srcset="" class="wd mt-70">
             <img src="@/assets/version2024/performance-9.png" alt="" srcset="" class="wd mt-70">
             <img src="@/assets/version2024/performance-10.png" alt="" srcset="" class="wd mt-70">
             <img src="@/assets/version2024/performance-11.jpg" alt="" srcset="" class="wd mt-70">
             <img src="@/assets/version2024/performance-12.png" alt="" srcset="" class="wd mt-70">
             <img src="@/assets/version2024/performance-13.png" alt="" srcset="" class="wd mt-70">
           </div>
          </div>
          <!-- 新年音乐会 -->
          <div
            class="pt-81 concert-wrap"
            style="background: #f3f3f3"
            v-if="active == 3"
          >
            <div class="contents">         
                <img src="@/assets/version2024/performance-14.png" alt="" srcset="" class="wd">
             <img src="@/assets/version2024/performance-15.png" alt="" srcset="" class="wd mt-70">
             <img src="@/assets/version2024/performance-16.png" alt="" srcset="" class="wd mt-70">
             <img src="@/assets/version2024/performance-17.png" alt="" srcset="" class="wd mt-70">
             <img src="@/assets/version2024/performance-18.png" alt="" srcset="" class="wd mt-70">
             <!-- <img src="@/assets/version2024/performance-19.png" alt="" srcset="" class="wd mt-70">
             <img src="@/assets/version2024/performance-20.png" alt="" srcset="" class="wd mt-70"> -->
            </div>
          </div>     
        </div>
      </div>
      <footNav />
    </div>
  </template>
  
  <script>
  import headNav from "../components/header2.vue";
  import footNav from "../components/footer2.vue";
  export default {
    components: { headNav, footNav },
    data() {
      return {
        active: 1,
      };
    },
    methods: {
      change(type) {
        this.active = type;
      },
    },
  };
  </script>
  
  <style scoped lang="scss">
  .common-contents {
    background-color: #f2f6f7;
    padding-bottom: 120px;
    .banner {
      .img {
        width: 100%;
        max-height: 682px;
      }
    }
    .c-1 {
      color: #464646;
    }
    .c-2 {
      color: #999;
    }
    .l-2 {
      letter-spacing: 2px;
    }
    .contents {
      width: 1200px;
      margin: 0 auto;
    }
    .content-item {
      width: 315px;
      line-height: 60px;
      border: 1px solid #666666;
      color: #464646;
    }
    .content-item-acitve {
      color: #835c46;
      font-weight: bold;
      border: 1px solid #835c46;
      position: relative;
      &::after {
        content: "";
        width: 315px;
        height: 3px;
        background: #835c46;
        position: absolute;
        bottom: -3px;
        left: -1px;
        z-index: 10;
      }
    }
    .new-item {
      padding-bottom: 24px;
      border-bottom: 1px solid #e7e7e7;
      &:last-child {
        margin-bottom: 0 !important;
      }
    }
    .shell-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 447px;
  
      .shell {
        width: 100%;
        height: 447px;
        display: flex;
      }
      .box {
        flex: 1;
        overflow: hidden;
        transition: 0.5s;
        position: relative;
      }
      .box > img {
        width: 100%;
        height: 100%;
        /* 对图片进行剪切，保留原始比例 */
        object-fit: cover;
        transition: 0.5s;
      }
      .box > span {
        position: absolute;
        left: 21px;
        bottom: 11px;
        z-index: 10;
        font-size: 18px;
        color: #fff;
        opacity: 0;
        transition: 0.5s;
      }
      .box:hover {
        flex-basis: 36%;
      }
      .box:hover > img {
        width: 100%;
        height: 100%;
      }
      .box:hover > span {
        opacity: 1;
      }
    }
    .show-item {
      width: 1200px;
      height: 420px;
      border-radius: 24px;
      overflow: hidden;
      position: relative;
      .show-item-text {
        font-size: 26px;
        color: #ffffff;
        letter-spacing: 4px;
        position: absolute;
        left: 56px;
        top: 161px;
        z-index: 10;
        font-weight: 400;
      }
    }
    .dynamic-wrap {
      .dynamic-item {
        width: 378px;
        transition: transform 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        &-img {
          width: 378px;
          height: 220px;
        }
        &-text {
          padding: 16px;
          background: #fff;
          .text-1 {
            font-size: 18px;
            font-weight: 500;
            color: #4b4b4b;
            letter-spacing: 2px;
          }
          .text-2 {
            font-size: 14px;
            font-weight: 400;
            color: #666666;
            letter-spacing: 1px;
          }
          .text-3 {
            font-size: 15px;
            font-family: DIN, DIN-Regular;
            color: #666666;
          }
        }
        &:hover {
          box-shadow: 0 1px 3px rgba(64, 71, 75, 0.1),
            0 16px 32px -4px rgba(64, 71, 75, 0.1);
          transform: translateY(-1px);
        }
      }
    }
    .activities-wrap {
      .activities-img {
        width: 640px;
        height: 339px;
      }
      .activities-text {
        width: 560px;
        height: 339px;
        background: #ffffff;
        padding: 52px 39px 37px;
        .text-1 {
          font-size: 20px;
          color: #4b4b4b;
          letter-spacing: 2px;
        }
        .text-2 {
          font-size: 14px;
          font-weight: 300;
          color: rgba(64, 71, 75, 0.8);
          letter-spacing: 1px;
        }
        .text-3 {
          font-size: 14px;
          font-family: Roboto, Roboto-Light;
          font-weight: 300;
          line-height: 32px;
          color: rgba(64, 71, 75, 0.8);
        }
        .btn {
          width: 100px;
          height: 32px;
          border: 1px solid rgba(64, 71, 75, 0.3);
          line-height: 30px;
          text-align: center;
          font-size: 14px;
          color: rgba(64, 71, 75, 0.8);
        }
      }
      .line {
        width: 640px;
        height: 4px;
        background: rgba(131, 92, 70, 1);
        position: relative;
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          right: 0;
          width: 440px;
          height: 4px;
          background: rgba(231, 231, 231, 1);
          z-index: 10;
        }
      }
    }
    .concert-wrap {
      .title {
        width: 875px;
        height: 72px;
        background: #835c46;
        font-size: 26px;
        font-weight: 500;
        color: #ffffff;
        line-height: 70px;
        letter-spacing: 2px;
        text-align: center;
      }
    }
  }
  </style>